<template>
    <div>
        <titulo-visualizar-matricula />
        <b-row class="mt-2">
            <b-col class="col-12">
                <dados-gerais :dados="matricula" />
            </b-col>
            <b-col class="col-12 mt-4">
                <responsaveis :responsaveis="matricula.vinculos" />
            </b-col>
            <div class="col-12 mt-4">
                <endereco :enderecos="matricula.enderecos" />
            </div>
        </b-row>
        <!-- action buttons -->
        <b-row>
            <b-col class="mt-5 col-12 d-flex justify-content-center">
                <router-link to="/protocolo">
                    <b-button class="mr-0 mr-md-3" variant="secondary">{{
                        $t('MATRICULAS.REPORTAR_ERRO')
                    }}</b-button>
                </router-link>
                <b-button variant="info" @click="visualizarContratoMatricula">{{
                    $t('ACADEMICO.BAIXAR_CONTRATO_ATUAL')
                }}</b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// utils e aux
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import breadcrumb from '@/common/utils/breadcrumb';
import pdf from '@/common/utils/pdf';
import mensagem from '@/common/utils/mensagem';

//services
import ContratoService from '@/common/services/contratos/contratos.service.js';

//components
import DadosGerais from '../components/DadosGerais';
import Responsaveis from '../components/Responsaveis';
import Endereco from '../components/Endereco';
import TituloVisualizarMatricula from './TituloVisualizarMatricula';

export default {
    components: {
        DadosGerais,
        Endereco,
        Responsaveis,
        TituloVisualizarMatricula,
    },
    mounted() {
        breadcrumb.definir(this.$store, [{ titulo: 'Matrícula' }]);
        this.matricula = this.$store.state.matriculas.matricula;
    },
    data() {
        return {
            matricula: [],
        };
    },
    methods: {
        visualizarContratoMatricula() {
            this.$store.dispatch(START_LOADING);
            ContratoService.buscarPorMatricula(this.$route.params.id)
                .then(async ({ data }) => {
                    if (data.itens[0]) {
                        const result = await ContratoService.baixarPDF(
                            data.itens[0]['id']
                        );
                        if (result?.data) {
                            if (result.data.documento) {
                                //converte os bytes em array:
                                let bytes = pdf.base64ToArrayBuffer(
                                    result.data.documento
                                );
                                // converte o array em blob
                                let blob = new Blob([bytes], {
                                    type: 'application/pdf',
                                });
                                // faz download do PDF:
                                var link = document.createElement('a');
                                link.href = window.URL.createObjectURL(blob);
                                link.download = result.data.nomeDocumento;
                                link.click();
                            }
                        }
                    }
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },
    },
};
</script>
