<template>
    <div>
        <Title title="Lista de Responsáveis" />
        <b-table
            :items="responsaveis"
            :fields="tabela.fields"
            :empty-text="$t('GERAL.NENHUM_REGISTRO')"
            head-variant="light"
            stacked="md"
            show-empty
            responsive
            striped
            hover
        />
    </div>
</template>
<script>
import Title from '@/components/Title';

export default {
    props: ['responsaveis'],
    components: { Title },
    data() {
        return {
            tabela: {
                fields: [
                    {
                        key: 'codigo',
                        label: 'Código pessoa',
                        sortable: true,
                    },
                    {
                        key: 'nome',
                        label: 'Nome',
                        sortable: true,
                    },
                    {
                        key: 'tipoFiliacaoDescricao',
                        label: 'Filiação',
                        sortable: true,
                    },
                    {
                        key: 'tipoResponsavelDescricao',
                        label: 'Responsável por',
                        sortable: true,
                    },
                ],
            },
        };
    },
};
</script>
