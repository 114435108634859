<template>
    <div>
        <Title title="Lista de Endereços" />
        <b-table
            :fields="tabela.fields"
            :items="enderecos"
            :empty-text="$t('GERAL.NENHUM_REGISTRO')"
            head-variant="light"
            stacked="md"
            show-empty
            responsive
            striped
            hover
        />
    </div>
</template>
<script>
import Title from '@/components/Title.vue';
export default {
    components: { Title },
    props: ['enderecos'],
    data() {
        return {
            tabela: {
                fields: [
                    {
                        key: 'cep',
                        label: 'Cep',
                        sortable: true,
                    },
                    {
                        key: 'logradouro',
                        label: 'logradouro',
                        sortable: true,
                    },
                    {
                        key: 'numero',
                        label: 'número',
                        sortable: true,
                    },
                    {
                        key: 'complemento',
                        label: 'complemento',
                        sortable: true,
                    },
                    {
                        key: 'principalTexto',
                        label: 'Endereço principal',
                        sortable: true,
                    },
                ],
            },
        };
    },
};
</script>
