<template>
    <div>
        <Title title="Dados do Aluno" />
        <b-row class="mt-3">
            <input-text
                v-model="dados.nomeAluno"
                class="col-4"
                label="Nome completo (como está no documento oficial)"
                :disabled="true"
            />
            <input-text
                v-model="dados.turma"
                class="col-4"
                placeholder=""
                label="Turma"
                :disabled="true"
            />
            <input-text
                v-model="dados.idiomaDescricao"
                class="col-4"
                label="Idioma do curso"
                :disabled="true"
            />
            <input-text
                v-model="dados.periodoLetivoDescricao"
                class="col-4"
                label="Período letivo"
                :disabled="true"
            />
        </b-row>
    </div>
</template>
<script>
import Title from '@/components/Title.vue';
import InputText from '@/components/inputs/InputText.vue';
export default {
    props: ['dados'],
    components: {
        Title,
        InputText,
    },
};
</script>
