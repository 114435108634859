import ApiService from '@/common/api/api.service';
import JwtService from '@/common/utils/jwt';

const ContratoService = {
    async buscarPorId(id) {
        let { data } = await ApiService.get(`Contrato/${id}`);
        return data;
    },
    async buscarPorMatricula(
        matriculaId,
        tamanhoDaPagina = 1000,
        numeroDaPagina = 1
    ) {
        let { data } = await ApiService.get(
            `Contrato/matricula/${matriculaId}`,
            {
                params: {
                    matriculaId,
                    tamanhoDaPagina,
                    numeroDaPagina,
                },
            }
        );
        return data;
    },
    async visualizarContratoPDF(id) {
        let { data } = await ApiService.get(
            `Contrato/visualizar-contrato/${id}`
        );
        return data;
    },
    async baixarPDF(id) {
        let { data } = await ApiService.get(`Contrato/imprimir/${id}`);
        return data;
    },
    async rematriculaLote(form) {
        let { data } = await ApiService.post(`Contrato/rematricula-lote`, form);
        return data;
    },
    async pegarContratosPorMatriculaIdEAnoLetivoId({
        paginacao,
        matriculaId,
        anoLetivoId,
    }) {
        let { data } = await ApiService.get(
            `Contrato/contratos-portal-do-aluno`,
            {
                params: { ...paginacao, matriculaId, anoLetivoId },
            }
        );
        return data;
    },
    async buscarAnosLetivos() {
        let { data } = await ApiService.get(`AnoLetivo`);
        return data;
    },
    async buscarContratoPorUsuarioAnoLetivo(anoLetivoId, paginacao) {
        let usuarioId = JwtService.buscarId();
        let { data } = await ApiService.post(
            `Contrato/obter-todos-contratos?TamanhoDaPagina=${paginacao.tamanhoDaPagina}&NumeroDaPagina=${paginacao.numeroDaPagina}&UsuarioId=${usuarioId}&AnoLetivoId=${anoLetivoId}`
        );
        return data;
    },
};

export default ContratoService;
