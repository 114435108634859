<template>
    <b-form-group :label-sr-only="noLabel">
        <template #label>
            <span>{{ $t(label) }}</span>
            <span v-if="required && label" class="text-danger">*</span>
        </template>
        <b-input-group>
            <b-form-input
                v-model="content"
                :state="state"
                @input="handleInput"
                :type="type"
                :autocomplete="autocomplete"
                :readonly="readonly"
                :formatter="formatter"
                :placeholder="this.$t(placeholder)"
                :lazy="lazy"
                @wheel="$event.target.blur()"
                :min="min"
                :max="max"
                :size="size"
                @keyup.enter="sendEnterEvent"
                @keyup="sendKeydownEvent"
                @change="setChanged"
                :disabled="disabled"
            />
            <button
                v-if="showPassword"
                type="button"
                class="button-eye"
                @click="changePasswordField"
            >
                <v-icon>{{ inputPasswordIcon }}</v-icon>
            </button>
            <slot name="append"></slot>
            <b-form-invalid-feedback v-if="!hideFeedback">
                {{ feedback }}
            </b-form-invalid-feedback>
        </b-input-group>

        <!-- EXIBIÇÃO DO LIMITE DE CARACTERES PERMITIDOS -->
        <small class="lengthLimit__text" v-if="maxLength && showLengthLimit">
            {{ lengthLimit }}
        </small>
    </b-form-group>
</template>

<script>
export default {
    name: 'InputText',
    props: {
        maxValue: { type: Number, default: null },
        stateCustom: { type: Boolean, default: true },
        value: { type: [String, Number], default: '' },
        type: { type: String, default: 'text' },
        contactType: { type: String, default: '' },
        label: { type: String },
        size: { type: String, default: 'lg' },
        autocomplete: { type: String, default: 'new-password' },
        upper: { type: Boolean },
        noLabel: { type: Boolean, default: false },
        removeSpecialChars: { type: Boolean },
        removeWhiteSpaces: { type: Boolean },
        required: { type: Boolean },
        requiredMessage: { type: String },
        readonly: { type: Boolean, default: false },
        length: { type: Number, default: 0 },
        lengthMessage: { type: String },
        showLengthLimit: { type: Boolean, default: true },
        minLength: { type: Number, default: null },
        minLengthMessage: { type: String },
        maxLength: { type: Number, default: null },
        maxLengthMessage: { type: String },
        placeholder: { type: String },
        invalidEmailMessage: { type: String },
        validate: { type: Boolean, default: null },
        showPassword: { type: Boolean, default: false },
        lazy: { type: Boolean, default: false },
        min: { type: [Number, String], required: false },
        max: { type: [Number, String], required: false },
        hideFeedback: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        enter: { type: Boolean, default: false },
        limparValidacao: { type: Boolean, default: false },
    },
    data() {
        return {
            content: this.value,
            validForm: false,
            hasError: false,
            errorMessage: '',
            inputPasswordIcon: 'fas fa-eye',
            changed: false,
            isField: true,
            lengthLimit: `0/${this.maxLength}`,
        };
    },
    computed: {
        wasChanged() {
            return this.changed;
        },
        requiredValid() {
            return !(
                this.required &&
                (this.content == '' || this.content == null)
            );
        },
        minValid() {
            return !(this.min && parseInt(this.content) < parseInt(this.min));
        },
        maxValid() {
            return !(this.max && parseInt(this.content) > parseInt(this.max));
        },
        lengthValid() {
            return !(
                this.length > 0 && String(this.content)?.length != this.length
            );
        },
        minLengthValid() {
            return !(
                this.minLength > 0 && this.content?.length < this.minLength
            );
        },
        maxLengthValid() {
            return !(
                this.maxLength > 0 && this.content?.length > this.maxLength
            );
        },
        validEmail() {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(this.content);
        },
        validCell() {
            var re = /\(\d{2}\)\s\d{5}-\d{4}/;
            return re.test(this.content);
        },
        validPhone() {
            var re = /\(\d{2}\)\s\d{4}-\d{4}/;
            return re.test(this.content);
        },
        validFax() {
            var re = /^\+?[0-9]{10}$/;
            return re.test(this.content);
        },
        state() {
            if (!this.stateCustom) return false;

            if (!this.validForm) return null;

            if (!this.requiredValid) return false;
            if (!this.minValid) return false;
            if (!this.maxValid) return false;
            if (!this.lengthValid) return false;
            if (!this.minLengthValid) return false;
            if (!this.maxLengthValid) return false;
            if (this.type == 'email') return this.validEmail;
            if (this.contactType == 'E-mail') return this.validEmail;
            if (this.contactType == 'Celular') return this.validCell;
            if (this.contactType == 'Residencial') return this.validPhone;
            if (this.contactType == 'Comercial') return this.validPhone;
            if (this.contactType == 'Fax') return this.validFax;
            if (this.hasError) return false;
            if (this.validate != null) return this.validate;

            return true;
        },
        feedback() {
            if (!this.requiredValid)
                return (
                    this.requiredMessage ||
                    this.$t('CAMPOS_VALIDACAO.REQUERIDO').formatUnicorn({
                        name: this.label,
                    })
                );
            if (!this.minValid)
                return (
                    this.requiredMessage ||
                    this.$t('CAMPOS_VALIDACAO.VALOR_MINIMO').formatUnicorn({
                        min: this.min,
                    })
                );
            if (!this.maxValid)
                return (
                    this.requiredMessage ||
                    this.$t('CAMPOS_VALIDACAO.VALOR_MAXIMO').formatUnicorn({
                        max: this.max,
                    })
                );
            if (!this.lengthValid)
                return (
                    this.lengthMessage ||
                    this.$t('CAMPOS_VALIDACAO.TAMANHO').formatUnicorn({
                        name: this.label,
                        length: this.length,
                    })
                );
            if (!this.minLengthValid)
                return (
                    this.minLengthMessage ||
                    this.$t(
                        'CAMPOS_VALIDACAO.COMPRIMENTO_MINIMO'
                    ).formatUnicorn({
                        name: this.label,
                        length: this.minLength,
                    })
                );
            if (!this.maxLengthValid)
                return (
                    this.maxLengthMessage ||
                    this.$t(
                        'CAMPOS_VALIDACAO.COMPRIMENTO_MAXIMO'
                    ).formatUnicorn({
                        name: this.label,
                        length: this.maxLength,
                    })
                );
            if (
                (this.type == 'email' && !this.validEmail) ||
                (this.validate != null && !this.validate)
            )
                return (
                    this.invalidEmailMessage ||
                    this.$t('CAMPOS_VALIDACAO.INVALIDO').formatUnicorn({
                        name: this.label,
                    })
                );
            if (
                (this.contactType == 'E-mail' && !this.validEmail) ||
                (this.validate != null && !this.validate)
            )
                return (
                    this.invalidEmailMessage ||
                    this.$t('CAMPOS_VALIDACAO.EMAIL_INVALIDO').formatUnicorn({
                        name: this.label,
                    })
                );
            if (
                (this.contactType == 'Celular' && !this.validCell) ||
                (this.validate != null && !this.validate)
            )
                return (
                    this.invalidEmailMessage ||
                    this.$t('CAMPOS_VALIDACAO.CELULAR_INVALIDO').formatUnicorn({
                        name: this.label,
                    })
                );

            if (
                (this.contactType == 'Residencial' && !this.validPhone) ||
                (this.validate != null && !this.validate)
            )
                return this.$t(
                    'CAMPOS_VALIDACAO.NUMERO_RESIDENCIAL_INVALIDO'
                ).formatUnicorn({ name: this.label });

            if (
                (this.contactType == 'Comercial' && !this.validPhone) ||
                (this.validate != null && !this.validate)
            )
                return this.$t(
                    'CAMPOS_VALIDACAO.NUMERO_COMERCIAL_INVALIDO'
                ).formatUnicorn({ name: this.label });

            if (
                (this.contactType == 'Fax' && !this.validFax) ||
                (this.validate != null && !this.validate)
            )
                return (
                    this.invalidEmailMessage ||
                    this.$t('CAMPOS_VALIDACAO.FAX_INVALIDO').formatUnicorn({
                        name: this.label,
                    })
                );

            if (this.hasError) return this.errorMessage;
            return '';
        },
    },
    methods: {
        showError(message) {
            this.validForm = true;
            this.hasError = true;
            this.errorMessage = message;
        },
        formatter(e) {
            if (e) {
                if (this.upper) e = e.toUpperCase();

                if (this.maxLength > 0 && e.length > this.maxLength) {
                    e = e.substring(0, this.maxLength);
                }

                if (this.removeSpecialChars)
                    e = e.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-');

                if (this.removeWhiteSpaces) e = e.replace(/\s/g, '');
            }

            return e;
        },
        handleInput() {
            this.$emit('input', this.content);
            this.hasError = false;
        },
        valid() {
            this.validForm = true;
            return this.state;
        },
        clear() {
            this.content = '';
            this.validForm = false;
            this.$emit('input', this.content);
        },
        changePasswordField() {
            if (this.type == 'text') {
                this.type = 'password';
                this.inputPasswordIcon = 'fas fa-eye';
            } else {
                this.type = 'text';
                this.inputPasswordIcon = 'fas fa-eye-slash';
            }
        },
        phoneMask(v, t) {
            let r = v.replace(/\D/g, '');
            r = r.replace(/^0/, '');

            let telefone = v.replace(/\D/g, '');
            telefone = telefone.replace(/^0/, '');

            if (r.length > 11) {
                r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
                telefone = telefone.replace(
                    /^(\d\d)(\d{4})(\d{4}).*/,
                    '($1) $2-$3'
                );
            } else if (r.length > 7) {
                telefone = telefone.replace(
                    /^(\d\d)(\d{4})(\d{0,4}).*/,
                    '($1) $2-$3'
                );
                r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
            } else if (r.length > 2) {
                telefone = telefone.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
                r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
            } else if (v.trim() !== '') {
                telefone = telefone.replace(/^(\d*)/, '($1');
                r = r.replace(/^(\d*)/, '($1');
            }
            return t ? telefone : r;
        },
        faxMask(fax) {
            let faxRegex = fax.replace(/\D/g, '');
            faxRegex.replace(/^\+?\d[10]{0,10}$/);

            if (faxRegex.length > 10) return faxRegex.slice(0, 10);

            return faxRegex;
        },
        sendKeydownEvent() {
            if (this.maxLength) {
                this.lengthLimit = `${this.content.length}/${this.maxLength}`;
            }
            if (this.contactType == 'Celular')
                this.content = this.phoneMask(this.content, false);

            if (this.contactType == 'Residencial')
                this.content = this.phoneMask(this.content, true);

            if (this.contactType == 'Comercial')
                this.content = this.phoneMask(this.content, true);

            if (this.contactType == 'Fax')
                this.content = this.faxMask(this.content);
        },
        sendEnterEvent() {
            this.$emit('enter');
        },
        setChanged() {
            this.changed = true;
        },
    },
    watch: {
        value: {
            handler(value) {
                this.content = value;
            },
            deep: true,
            immediate: true,
        },
        content(val) {
            this.$emit('input', val);
            this.$emit('change', val);
        },
        limparValidacao: {
            handler(value) {
                if (value) {
                    this.clear();
                }
            },
        },
    },
};
</script>
<style lang="scss" scoped>
input[readonly] {
    background-color: #f2f3f8 !important;
}
.button-eye {
    position: absolute;
    top: 31px;
    right: 31px;
    z-index: 9;

    .fas {
        font-size: 17px;
    }
}
.lengthLimit__text {
    float: right;
}
.input-group:not(.has-validation) > .form-control:not(:last-child) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
</style>
